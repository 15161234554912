<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Dinitrogen pentoxide,
        <chemical-latex content="N2O5," />
        decomposes as follows:
      </p>

      <p class="mb-5 pl-8">
        <chemical-latex content="2 N2O5 -> 4 NO2 + O2" />
      </p>

      <p class="mb-1">
        Given the set of concentrations at various times, determine the average
        <b>rate of decomposition of dinitrogen pentoxide</b> over each defined time interval.
      </p>

      <v-simple-table style="max-width: 400px" class="pl-8 mb-5">
        <thead>
          <tr>
            <th>
              <stemble-latex content="$\textbf{Time:}$" />
            </th>
            <th>
              <latex-number number="0" unit="\text{s}" />
            </th>
            <th>
              <latex-number number="125" unit="\text{s}" />
            </th>
            <th>
              <latex-number number="350" unit="\text{s}" />
            </th>
            <th>
              <latex-number number="600" unit="\text{s}" />
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <stemble-latex content="$\ce{[N2O5]}\text{ (M):}$" />
            </td>
            <td>
              <latex-number number="3.05" />
            </td>
            <td>
              <latex-number number="2.20" />
            </td>
            <td>
              <latex-number number="1.80" />
            </td>
            <td>
              <latex-number number="1.50" />
            </td>
          </tr>
        </tbody>
      </v-simple-table>

      <p class="mb-2">
        <stemble-latex content="$\text{a) 0-125 s}$" />
      </p>

      <calculation-input
        v-model="inputs.rate1"
        prepend-text="$\text{Rate:}$"
        append-text="$\text{M/s}$"
        class="mb-5"
        :disabled="!allowEditing"
      />

      <p class="mb-2">
        <stemble-latex content="$\text{b) 125-350 s}$" />
      </p>

      <calculation-input
        v-model="inputs.rate2"
        prepend-text="$\text{Rate:}$"
        append-text="$\text{M/s}$"
        class="mb-5"
        :disabled="!allowEditing"
      />

      <p class="mb-2">
        <stemble-latex content="$\text{c) 350-600 s}$" />
      </p>
      <calculation-input
        v-model="inputs.rate3"
        prepend-text="$\text{Rate:}$"
        append-text="$\text{M/s}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ChemicalLatex from '../displayers/ChemicalLatex';
import LatexNumber from '../displayers/LatexNumber';
import CalculationInput from '../inputs/CalculationInput';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question83',
  components: {
    StembleLatex,
    ChemicalLatex,
    LatexNumber,
    CalculationInput,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        rate1: null,
        rate2: null,
        rate3: null,
      },
    };
  },
};
</script>
